import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideBarMenu: "homeTab",
};

export const sidBarSliceMenu = createSlice({
  name: "sidebarIcon",
  initialState,
  reducers: {
    tabReducer: (state, action) => {
      state.sideBarMenu = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { tabReducer } = sidBarSliceMenu.actions;

export default sidBarSliceMenu.reducer;
