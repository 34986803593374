import React from "react";
import classes from "./BottomNavigation.module.css";
import PropTypes from "prop-types";

const BottomNavigationElement = ({
  BottomNavigationIcon,
  BottomNavigationIconAlt,
  BottomNavigationIconLabel,
}) => {
  return (
    <div className={classes["main-container"]}>
      <img
        src={BottomNavigationIcon}
        alt={BottomNavigationIconAlt}
        loading="lazy"
      />
      <label className={classes["label"]}>{BottomNavigationIconLabel}</label>
    </div>
  );
};

BottomNavigationElement.propTypes = {
  BottomNavigationIcon: PropTypes.string,
  BottomNavigationIconAlt: PropTypes.string,
  BottomNavigationIconLabel: PropTypes.string,
};

export default BottomNavigationElement;
