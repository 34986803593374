import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { imageBaseURL } from "../../constants/utils";
const Loader = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={true}
    >
      <img
        src={`${imageBaseURL}/GIF/loader.gif`}
        alt="loader"
        width={"200px"}
        loading="lazy"
      />
    </Backdrop>
  );
};

export default Loader;
