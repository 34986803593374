import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  caseId: "",
  caseStatus: "",
};

export const caseSearchSlice = createSlice({
  name: "caseSearchDetails",
  initialState,
  reducers: {
    storeCaseIdCaseSearchReducer: (state, action) => {
      state.caseId = action.payload;
    },
    caseSearchDataReducer: (state, action) => {
      state.caseStatus = action.payload;
    },
  },
});

export const { storeCaseIdCaseSearchReducer, caseSearchDataReducer } =
  caseSearchSlice.actions;
export default caseSearchSlice.reducer;
