import { useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Menuitem from "./Menuitem";
import style from "./Navigation.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import useToaster from "../../customHook/useToaster";
import { currentRoleNameObj } from "../../Utils/helper";
import {
  searchValueReducer,
  searchSourceReducer,
  searchTypeReducer,
} from "../../Redux/Slice/SearchSlice";
import CustomSelect from "../../CommonComponents/Select/CustomSelect";
import { GlobalContextMain } from "../../Context/GloblaContext";
import { imageBaseURL } from "../../constants/utils";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 40,
  backgroundColor: "#FAFAFA",
  marginLeft: 15,
  width: "35%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    width: "35%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#3D4756",
  fontSize: "1.4rem",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "32px",
    },
  },
}));

const mainHeadingStyle = {
  display: "block",
  ml: { xs: "0.5rem", md: "1.6rem" },
  mt: { xs: "1.8rem", md: "0" },
  fontSize: "2rem",
  fontWeight: 500,
};

const downArrow = {
  alignItems: "top",
  display: { xs: "none", sm: "flex", md: "flex" },
};

const notificationsIconSpace = {
  flexDirection: "column",
  ml: "3.2rem",
};

const investigationLabelTypography = {
  fontWeight: 400,
  color: "rgba(0, 0, 0, 0.4)",
  fontSize: "1.2rem",
};

const usernameTypography = {
  fontSize: "1.6rem",
  fontWeight: 500,
};
const options = ["Claim No", "Policy No", "Case No"];
const secondOption = ["Chase", "Cima"];
export default function Navigation({ disabled = false, boxShadow = true }) {
  const { isTabView } = useContext(GlobalContextMain);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { username, currentRoleName, token } = useSelector(
    (state) => state.loginSlice.userData
  );

  let { searchValue, searchSource, searchType } = useSelector(
    (state) => state.SearchSlice
  );

  const location = useLocation();
  const { showToast } = useToaster();

  const handleSearchInput = (value) => {
    dispatch(searchValueReducer(value));
  };

  const notificationClickHandler = () => {
    showToast("Coming Soon.", "info", true);
  };

  const handleChange = (e) => {
    const value = e?.target?.value;
    dispatch(searchTypeReducer(value));
  };
  const versionChangeHandler = (e) => {
    const value = e?.target?.value;
    dispatch(searchSourceReducer(value));
  };

  useEffect(() => {
    // if (location.pathname !== "/caseSearch" && searchValue !== "") {
    //   dispatch(searchValueReducer(""));
    // }
    if (
      location.pathname !== "/caseSearchResult" &&
      location.pathname !== "/caseSearchView"
    ) {
      dispatch(searchValueReducer(""));
      dispatch(searchSourceReducer(""));
      dispatch(searchTypeReducer(""));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (searchValue && searchSource && searchType)
      navigate("/caseSearchResult");
  }, [searchValue, searchSource, searchType]);

  currentRoleName = currentRoleNameObj[currentRoleName];

  return (
    <Box
      sx={{
        paddingTop: "64px",
        "& .MuiToolbar-root": {},
        "& .MuiPaper-root": {
          backgroundColor: "#FFFFFF",
          color: "#3D4756",
          border: "0px solid #E8E8E8",
          boxShadow: boxShadow ? "0px 4px 4px rgba(0, 0, 0, 0.05)" : "none",
          borderColor: "#E8E8E8",
        },
        "& .MuiInputBase-root ": { width: "100%" },
      }}
    >
      <AppBar position="fixed" sx={{ zIndex: 3 }}>
        <Toolbar>
          {token ? (
            <img
              className={style.chaseLogo}
              src={`${imageBaseURL}/Svg/chaseLogo.svg`}
              alt="chaseLogo"
              loading="lazy"
            />
          ) : (
            <img
              src={`${imageBaseURL}/Svg/Logo.svg`}
              alt="logo"
              loading="lazy"
            />
          )}

          {disabled === true ? null : (
            <>
              <div
                style={{
                  width: "18rem",
                  marginLeft: "20px",
                }}
              >
                <CustomSelect
                  options={secondOption}
                  placeholder={
                    isTabView ? "Select source" : "Select source system"
                  }
                  customSelectValue={searchSource}
                  handleChange={versionChangeHandler}
                  border={"0.1rem solid #F2F2F2"}
                  height={"3.5rem"}
                  marginBottom="0px"
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
              <div
                style={{
                  width: "18rem",
                  marginLeft: "20px",
                }}
              >
                <CustomSelect
                  options={options}
                  placeholder={isTabView ? "Select type" : "Select source type"}
                  customSelectValue={searchType}
                  handleChange={handleChange}
                  border={"0.1rem solid #F2F2F2"}
                  height={"3.5rem"}
                  marginBottom="0px"
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
              <Search
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                    md: "flex",
                  },
                  width: "100%",
                }}
              >
                <img
                  className={style.searchicon}
                  src={`${imageBaseURL}/Svg/Search.svg`}
                  alt="SearchLogo"
                  loading="lazy"
                />

                <StyledInputBase
                  disabled={!searchSource || !searchType}
                  value={searchValue}
                  placeholder={
                    isTabView
                      ? "Search"
                      : !searchType
                        ? "Kindly select search type, Case No, Policy No or Claim No & Source System"
                        : searchType
                  }
                  sx={{
                    width: "100%",
                    fontSize: "12px",
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => handleSearchInput(e.target.value?.trim())}
                />
              </Search>
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />
          {!disabled && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textWrap: "nowrap",
                marginLeft: "10px",
              }}
            >
              <IconButton
                size="large"
                onClick={notificationClickHandler}
                aria-label="show 17 new notifications"
                color="inherit"
                sx={{ p: 0, mt: "0.5rem" }}
              >
                {/* <Badge badgeContent={5} color="error"> */}
                <img
                  src={`${imageBaseURL}/Svg/Bell.svg`}
                  alt="logo"
                  loading="lazy"
                />
                {/* </Badge> */}
              </IconButton>
              {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              sx={{ ml: "3.5rem", p: 0 }}
            >
              <Badge badgeContent={4} color="error">
                <img src={ChatIcon} alt="logo" loading="lazy" />
              </Badge>
            </IconButton> */}
              <Box sx={downArrow}>
                <Box sx={notificationsIconSpace}>
                  <Typography sx={usernameTypography}>{username}</Typography>
                  <Typography sx={investigationLabelTypography}>
                    {currentRoleName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "0.2rem",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                >
                  <Menuitem />
                </Box>
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

Navigation.propTypes = {
  disabled: PropTypes.bool,
  boxShadow: PropTypes.bool,
};
