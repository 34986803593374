import { Box } from "@mui/system";
import React from "react";
import { Button } from "@mui/material";
import { imageBaseURL } from "../../constants/utils";
const ScrollToTop = () => {
  const scrollToTopClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <Box
      sx={{
        display: "flex",
        fontSize: "1.2rem",
        fontWeight: "400",
        alignItems: "center",
        justifyContent: "end",
        marginTop: "10px",
        marginBottom: "10px",
        marginRight: "25px",
      }}
    >
      <Button
        onClick={scrollToTopClick}
        sx={{
          color: "#332E3C",
          textTransform: "none",
        }}
      >
        Scroll To Top
        <img
          src={`${imageBaseURL}/Svg/ScrollToTop.svg`}
          style={{ marginLeft: "10px" }}
          alt="maker logo"
          loading="lazy"
        />
      </Button>
    </Box>
  );
};

export default ScrollToTop;
