import React from "react";
import Button from "@mui/material/Button";
import { PropTypes } from "prop-types";
import { Box } from "@mui/material";

const CommonButton = ({
  filledButton,
  label = "Default",
  disabled = false,
  onClick = () => {},
  isIcon = false,
  iconName,
  boxShadow,
  removeBackGround,
  uppercase,
  isColor = false,
  backgroundColor,
  textColor,
  isHover = false,
  hoverColor,
  removeBorder,
  borderRadius,
  sx = {},
  isUpload = false,
  onChangeHandler = () => {},
  fitContent,
  fontSize,
  isEndIcon = false,
  endIcon,
  id,
  isAudioVideoUpload = false,
  arialabel,
}) => {
  return (
    <Button
      id={id}
      disabled={disabled}
      aria-label={arialabel}
      component="label"
      sx={{
        opacity: disabled && 0.6,
        fontFamily: "Inter",
        textTransform: uppercase ? "uppercase" : "capitalize",
        fontSize: fontSize || "1.6rem",
        width: fitContent ? "fit-content" : "100%",
        height: "auto",
        background: filledButton
          ? "#E42125"
          : removeBackGround
            ? "#FAFAFA"
            : isColor
              ? backgroundColor
              : "rgba(228, 33, 37, 0.05)",
        border: filledButton
          ? "none"
          : removeBackGround || removeBorder
            ? "none"
            : "1px solid #E42125",
        borderRadius: borderRadius || "4px",
        color: filledButton ? "white" : isColor ? textColor : "#E7373B",
        boxShadow: boxShadow && "4px 4px 3px rgba(0, 0, 0, 0.25)",
        "&:hover": {
          backgroundColor: filledButton
            ? "#E42125"
            : removeBackGround
              ? "white"
              : isHover
                ? hoverColor
                : "rgba(228, 33, 37, 0.05)",
        },

        ...sx,
      }}
      onClick={(e) => onClick(e, label)}
      // square button
      style={{ minWidth: "38px" }}
    >
      {isUpload && (
        <input
          hidden
          accept={
            isAudioVideoUpload
              ? ".png, .jpg, .jpeg, .pdf, .heic, .heif, .webp, .mp3, .mp4, .wav, .aac, .tiff, .avi, .xlsx, .docx"
              : ".png, .jpg, .jpeg, .pdf, .heic, .heif, .webp, .wav, .aac, .tiff, .avi, .xlsx, .docx"
          }
          onChange={onChangeHandler}
          onClick={(e) => (e.target.value = null)}
          multiple
          type="file"
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "no-wrap",
          gap: "5px",
          fontSize: fontSize || "unset",
          alignItems: "center",
        }}
      >
        {isIcon && iconName}
        {label}
        {isEndIcon && endIcon}
      </Box>
    </Button>
  );
};

CommonButton.propTypes = {
  filledButton: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  isIcon: PropTypes.bool,
  iconName: PropTypes.element,
  boxShadow: PropTypes.bool,
  removeBackGround: PropTypes.bool,
  uppercase: PropTypes.bool,
  isColor: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  hoverColor: PropTypes.string,
  isHover: PropTypes.bool,
  removeBorder: PropTypes.bool,
  borderRadius: PropTypes.string, // extra
  isUpload: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  endIcon: PropTypes.element,
  isEndIcon: PropTypes.bool,
};
export default CommonButton;
