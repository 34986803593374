import axios from "axios";
import store from "../Redux/Store";
export const baseURL = process.env.REACT_APP_ROOT_URL;

const headers = {
  Accept: "application/json",
  //   "Content-Type": "multipart/form-data",
};
const apiInstance = axios.create({
  withCredentials: true,
  baseURL,
  headers,
});

apiInstance.interceptors.request.use(function (request) {
  //get value from ls
  const state = store.getState();
  request.headers.token = state.loginSlice.token;
  return request;
});

apiInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const isInternet = navigator?.onLine;
    if (!isInternet) {
      let error = {
        ...err,
        reponse: {
          data: {
            message: err?.message,
          },
        },
      };
      return Promise.reject(error);
    }
    if (err.response.status === 504) {
      let error = {
        ...err,
        response: {
          data: {
            message: "Taking longer than expected.",
          },
        },
      };
      return Promise.reject(error);
    }
    if (
      originalConfig.url !== "auth/login" &&
      originalConfig.url !== "auth/refresh" &&
      err.response &&
      err.response.status === 401 &&
      !originalConfig._retry
    ) {
      // Access Token was expired
      originalConfig._retry = true;
      try {
        // const res = await apiInstance.get("auth/refresh");
        // const { accessToken } = res.data;
        // TokenService.updateLocalAccessToken(accessToken);
        store?.dispatch({ type: "USER_LOGOUT" });
        return Promise.reject(err);
        // return apiInstance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    return Promise.reject(err);
  }
);

export { apiInstance };
