import { GlobalContextMain } from "../Context/GloblaContext";
import { useContext } from "react";

const useToaster = () => {
  const { setToaster } = useContext(GlobalContextMain);
  //showToast
  const showToast = (message, severity, isOpen, autoClose) => {
    setToaster({
      isOpen,
      message,
      severity,
      autoClose: autoClose === undefined ? true : autoClose,
    });
  };

  return { showToast };
};

export default useToaster;
