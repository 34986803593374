import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Menu, MenuItem } from "@mui/material";
import CommonButton from "../../CommonComponents/CommonButton";
import style from "./Menuitem.module.css";
import { switchRole } from "../../Services/login.service";
import useToaster from "../../customHook/useToaster";
import { saveToken, userDataReducer } from "../../Redux/Slice/loginSlice";
import { useNavigate } from "react-router";
import { imageBaseURL } from "../../constants/utils";
const MenuItemMyAccount = {
  fontSize: "1.3rem",
  display: "flex",
  gap: "1.3rem",
  paddingLeft: "2.1rem",
};
const MenuItemSwitchRole = {
  fontSize: "1.3rem",
  display: "flex",
  gap: "1.3rem",
  paddingLeft: "1.55rem",
};

const setting = {
  fontSize: "1.3rem",
  display: "flex",
  gap: "1.3rem",
  paddingLeft: "2.1rem",
};
const Menuitem = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  let { currentRoleName, availableRoleNames = [] } = useSelector(
    (state) => state.loginSlice.userData
  );
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const commonButtonStyle = {
    backgroundColor:
      selectedRole === "" ? "rgba(233, 77, 81, 0.30)" : "#E94D51",
    width: "4.3rem",
    color: "#fff",
    border: "1px solid #E94D51",
    height: "1.9rem",
    marginLeft: "1rem",
  };
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    setIsClicked(false);
    if (e?.target?.id === "setting") {
      showToast("Coming Soon.", "info", true);
    }
  };
  const switchRoleHandler = () => {
    setSelectedRole("");
    setIsClicked((prev) => !prev);
  };

  //GTM Event for User logged out
  const handleLogout = () => {
    dispatch({ type: "USER_LOGOUT" });
  };

  const getStyle = (currentRoleName) => {
    const styles = {
      NH: style.buttonswitchNH,
      IM: style.buttonswitchIM,
      QC: style.buttonswitchQC,
      RM: style.buttonswitchRM,
      NM: style.buttonswitchNM,
    };

    return styles[currentRoleName];
  };
  const getSelectedStyle = (selectedRole) => {
    const styles = {
      NH: style.buttonswitchselectedNH,
      IM: style.buttonswitchselectedIM,
      QC: style.buttonswitchselectedQC,
      RM: style.buttonswitchselectedRM,
      NM: style.buttonswitchselectedNM,
    };

    return styles[selectedRole];
  };

  const applyHandler = () => {
    switchRole({ targetRoleName: selectedRole })
      .then((data) => {
        if (data?.success) {
          setAnchorEl(null);
          dispatch(userDataReducer(data?.data));
          dispatch(saveToken(data?.data?.token));
          navigate("/home");
          showToast(data?.message, "success", true);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      })
      .catch((err) => {
        showToast("Error while switching role", "error", true);
      });
  };

  return (
    <div>
      <Button
        sx={{
          color: "white",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img
          src={`${imageBaseURL}/Svg/dropDown.svg`}
          alt="logo"
          loading="lazy"
        />
      </Button>
      <Menu
        sx={{
          marginTop: "3rem",
          zIndex: "10000",
        }}
        PaperProps={{
          sx: {
            width: { md: "14%", sm: "24%", xs: "50%" },
            borderRadius: "0px 0px 4px 4px",
          },
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose} sx={MenuItemMyAccount} id="my-account">
          <img
            src={`${imageBaseURL}/Svg/userIcon.svg`}
            alt="userIcon"
            loading="lazy"
          />{" "}
          My Account
        </MenuItem>
        <div
          style={
            isClicked
              ? {
                  backgroundColor: "#F2F2F2",
                  borderRadius: "10px",
                  marginLeft: "0.4rem",
                  marginRight: "0.4rem",
                }
              : null
          }
        >
          <MenuItem
            onClick={switchRoleHandler}
            sx={MenuItemSwitchRole}
            id="switch-role"
          >
            <img
              src={`${imageBaseURL}/Svg/switchRoleIcon.svg`}
              alt="switchIcon"
              loading="lazy"
            />{" "}
            Switch Role
          </MenuItem>
          {isClicked ? (
            <>
              <div className={style.switchRoleButton}>
                {availableRoleNames?.map((item) => {
                  return (
                    <button
                      key={item}
                      className={
                        currentRoleName === item
                          ? style.buttonSwitchDisabled
                          : selectedRole === item
                            ? getSelectedStyle(selectedRole)
                            : getStyle(item)
                      }
                      onClick={() => setSelectedRole(item)}
                      id={item}
                      disabled={currentRoleName === item}
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
              <div className={style.applyButtonDiv}>
                <CommonButton
                  id="menu-item-apply"
                  label="Apply"
                  borderRadius={"10px"}
                  isColor={true}
                  backgroundColor={"rgba(233, 77, 81, 0.30)"}
                  sx={commonButtonStyle}
                  isHover
                  hoverColor={"#E94D51"}
                  fontSize={"1rem"}
                  disabled={selectedRole === ""}
                  onClick={applyHandler}
                />
              </div>
            </>
          ) : null}
        </div>

        {/* <MenuItem onClick={handleClose} sx={setting} id="setting">
          <img src={settingIcon} alt="setting" />
          Settings
        </MenuItem> */}
        <MenuItem onClick={handleLogout} sx={setting} id="logout">
          <img
            src={`${imageBaseURL}/Svg/logoutIcon.svg`}
            alt="logout"
            loading="lazy"
          />{" "}
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Menuitem;
