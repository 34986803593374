import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { GlobalContextMain } from ".././Context/GloblaContext";
import { useContext } from "react";

/*
 import the ToastSnackbar component.
 place the ToastSnackbar component within JSX code
 import and use the func showToast(message, severity, true) from the useToaster hook to display toast notifications.
 severity = warning | info | success | error
*/

const ToastSnackbar = ({ variant, anchorVertical, anchorHorizontal }) => {
  const { toaster, setToaster } = useContext(GlobalContextMain);
  const { isOpen, message, severity, autoClose } = toaster;
  const [isHovered, setIsHovered] = useState(false);
  const [transition, setTransition] = useState(undefined);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (isOpen && !isHovered && autoClose) {
      const timer = setTimeout(() => {
        setToaster((prevToaster) => ({
          ...prevToaster,
          isOpen: false,
        }));
      }, 2600);

      return () => {
        clearTimeout(timer);
        setIsHovered(false);
      };
    }
  }, [isOpen, isHovered, setToaster, autoClose, toaster]);

  useEffect(() => {
    if (isOpen) {
      setTransition(() => TransitionLeft);
    }
  }, [isOpen]);

  const handleClose = () => {
    setToaster((prevToaster) => ({
      ...prevToaster,
      isOpen: false,
      autoClose: true,
    }));
    setIsHovered(false);
  };

  return (
    <div>
      <Snackbar
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        anchorOrigin={{
          vertical: anchorVertical ? anchorVertical : "top",
          horizontal: anchorHorizontal ? anchorHorizontal : "right",
        }}
        open={isOpen}
        TransitionComponent={transition}
        sx={{
          "& .MuiAlert-message": {
            fontSize: "1.6rem",
            fontWeight: "500",
          },
          "& .MuiPaper-root": {
            paddingRight: "2rem",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          },
        }}
        onClose={handleClose}
      >
        <Alert
          variant={variant ? variant : "standard"}
          severity={severity}
          onClose={handleClose}
          sx={{
            "& .MuiSvgIcon-fontSizeSmall": {
              fontSize: "1.9rem",
              paddingBottom: "2px",
            },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

export default ToastSnackbar;
