import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documentUpload: {},
};

export const documentSlice = createSlice({
  name: "uploadDocument",
  initialState,
  reducers: {
    documentUploadReducer: (state, action) => {
      state.documentUpload = { ...state.documentUpload, ...action.payload };
    },
    deleteFile: (state, action) => {
      const { key, fileName } = action.payload;
      const fileList = state.documentUpload[key];
      if (fileList) {
        const updatedFileList = fileList.filter(
          (file) => file.fileName !== fileName
        );
        state.documentUpload[key] = updatedFileList;
        if (updatedFileList?.length === 0) {
          delete state.documentUpload[key];
        }
      }
    },
    clearDocumentUopload: (state) => initialState,
  },
});

export const { documentUploadReducer, deleteFile, clearDocumentUopload } =
  documentSlice.actions;

export default documentSlice.reducer;
