import { apiInstance } from "./ApiInstance";

export const userLogin = async (payload) => {
  let { data = {} } = await apiInstance.post("/auth/v2/login", payload);
  return data;
};

export const switchRole = async (payload) => {
  let { data = {} } = await apiInstance.post("/auth/switch-role", payload);
  return data;
};
