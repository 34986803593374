import React, { useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import propTypes from "prop-types";
import { padding } from "@mui/system";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core";
import ExpandMore from "@mui/icons-material/ExpandMore";

// you can use label as string
// you can use customSelectValue as what value u have selected
// you can use handleChange function  as what value user is selecting
//you can use options with array like this  const options = ["Select" , "options" , "options"]
// you have to create one state on you component and set value in handleChange function and pass hold value of state to customSelectValue
const CustomSelect = ({
  label = "",
  customSelectValue,
  name,
  handleChange = () => {},
  options,
  sx = {},
  boxStyles = {},
  onClose = () => {},
  onOpen = () => {},
  open = null,
  openSelect = false,
  menuProps = {},
  afterSx = {},
  formStyles = {},
  labelStyles = {},
  labelFont = "1.4rem",
  searchable = false,
  required = false,
  dropDownIcon = ExpandMore,
  value,
  onChange = () => {},
  id,
  placeholder,
  disabled,
  placeholderColor,
  border,
  height,
  marginBottom,
  inputProps = {},
}) => {
  const [selectedOption, setSelectedOption] = useState(customSelectValue);
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    handleChange(event);
  };
  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: placeholderColor ? placeholderColor : "#aaa",
    },
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };
  const style = {
    ...sx,
    color:
      selectedOption === "Select" ||
      selectedOption === "Select search type" ||
      selectedOption === "Select source system"
        ? "#B0B0B0"
        : "#000000",
    // fontWeight: selectedOption === "Select" ? "normal" : "bold",
    // fontSize: fontSize || "1.5rem",
    fontSize: "1.3rem",
    borderRadius: "4px",
    height: height ? height : "4rem",
    border: border ? border : "1px solid #B0B0B0",
    ".MuiSelect-select": {
      padding: padding || "9px",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    ".MuiSvgIcon-root ": {
      fontSize: "2rem",
      color: "#B0B0B0",
    },
    ...afterSx,
  };
  const styleAutomated = {
    color: "#64758B",
    fontSize: "1.2rem",
    borderRadius: "4px",
    border: "1px solid #D9D9D9",
    ".MuiInputBase-root": {
      padding: "0px",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.Mui-focused .MuiOutlinedInput-root": {
      border: 0,
    },
    "&:hover .MuiInputBase-notchedOutline": {
      border: 0,
    },
    ".MuiSvgIcon-root ": {
      fontSize: "2rem",
      color: "#3A3A3A",
    },
    ...sx,
  };

  return (
    <Box sx={{ ...boxStyles }}>
      <p
        style={{
          fontSize: labelFont,
          color: "#7C7C7C",
          marginBottom: marginBottom ? marginBottom : "0.6rem",
          ...labelStyles,
        }}
      >
        {label + " "}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
      </p>
      <FormControl fullWidth sx={formStyles}>
        {searchable ? (
          <Autocomplete
            disabled={disabled ? disabled : null}
            placeholder={placeholder}
            disablePortal
            labelid="demo-simple-select-label"
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            defaultValue={customSelectValue}
            options={options}
            sx={styleAutomated}
            renderInput={(params) => (
              <TextField {...params} placeholder={placeholder} />
            )}
          />
        ) : openSelect ? (
          <Select
            labelid="demo-simple-select-label"
            id={id}
            name={name}
            value={customSelectValue}
            displayEmpty
            onChange={handleChange}
            sx={style}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            defaultValue={customSelectValue}
            renderValue={
              !customSelectValue
                ? () => <Placeholder>{placeholder}</Placeholder>
                : undefined
            }
            IconComponent={dropDownIcon}
            MenuProps={menuProps}
          >
            {options?.map((item) => (
              <MenuItem value={item} sx={{ fontSize: "1.4rem" }} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select
            labelid="demo-simple-select-label"
            id={id}
            name={name}
            value={customSelectValue}
            displayEmpty
            onChange={handleChange}
            sx={style}
            defaultValue={customSelectValue}
            renderValue={
              !customSelectValue
                ? () => <Placeholder>{placeholder}</Placeholder>
                : undefined
            }
            IconComponent={dropDownIcon}
            MenuProps={menuProps}
            disabled={disabled ? disabled : null}
            inputProps={inputProps}
          >
            {options?.map((item) => (
              <MenuItem
                // disabled={item === "Select" ? true : false}
                value={item}
                sx={{ fontSize: "1.4rem" }}
                key={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};

CustomSelect.propTypes = {
  label: propTypes.string,
  sx: propTypes.object,
  customSelectValue: propTypes.string,
  name: propTypes.string,
  handleChange: propTypes.func,
  options: propTypes.array,
};

export default CustomSelect;
