import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  investigatorDetails: {
    vendorName: "",
    mobileNumber: "",
    city: "",
    email: "",
  },
  blocksAssigned: [],
};

export const approvalVendorSlice = createSlice({
  name: "investigatorDetails",
  initialState,
  reducers: {
    investigatorReducer: (state, action) => {
      state.investigatorDetails = {
        ...state.investigatorDetails,
        ...action.payload,
      };
    },
    blocksAssignedReducer: (state, action) => {
      // state.blocksAssigned = { ...state.blocksAssigned, ...action.payload };
      state.blocksAssigned = action.payload;
    },
  },
});
export const { investigatorReducer, blocksAssignedReducer } =
  approvalVendorSlice.actions;
export default approvalVendorSlice.reducer;
