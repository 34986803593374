import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  caseManagementMenu: {
    caseManagementMenuTab: "Open",
    caseManagementMenuTabIndex: 0,
  },
  caseActions: {},
};

export const caseManagemenSliceMenu = createSlice({
  name: "caseManagement",
  initialState,
  reducers: {
    caseManagementMenuReducer: (state, action) => {
      state.caseManagementMenu = action.payload;
    },
    setCaseActions: (state, action) => {
      let actionKey = action?.payload?.actionType;
      let actionVal = state?.caseActions?.[actionKey];
      let caseNo = action?.payload?.caseNo;
      if (!actionVal) actionVal = [];
      if (!actionVal?.includes(Number(caseNo))) actionVal?.push(Number(caseNo));
      state.caseActions = { ...state.caseActions, [actionKey]: actionVal };
    },
  },
});

// Action creators are generated for each case reducer function
export const { caseManagementMenuReducer, setCaseActions } =
  caseManagemenSliceMenu.actions;

export default caseManagemenSliceMenu.reducer;
