import React, { createContext, useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

export const GlobalContextMain = createContext(null);

const GloblaContextProvider = ({ children }) => {
  const [isTabView, setIsTabView] = useState(false);
  const [toaster, setToaster] = useState({
    open: false,
    message: "",
    severity: "success",
    autoClose: true,
  });

  const minWidth = useMediaQuery("(min-width:600px)");
  const maxWidth = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    setIsTabView(minWidth && maxWidth);
  }, [minWidth, maxWidth]);

  return (
    <GlobalContextMain.Provider value={{ toaster, setToaster, isTabView }}>
      {children}
    </GlobalContextMain.Provider>
  );
};

export default GloblaContextProvider;
