import { Box } from "@mui/system";
import React from "react";
import { useLocation } from "react-router";
import { imageBaseURL } from "../../constants/utils";
const Footer = () => {
  const location = useLocation();
  return (
    <Box
      sx={{
        display: "flex",
        fontSize: "1.2rem",
        fontWeight: "400",
        alignItems: "center",
        justifyContent: "center",
        marginTop:
          location?.pathname === "/login" || location?.pathname === "/"
            ? "58px"
            : "30px",
        marginBottom: "50px",
      }}
    >
      Powered by{" "}
      <img
        src={`${imageBaseURL}/Svg/ctrl+nLogo.svg`}
        alt="maker logo"
        loading="lazy"
      />
    </Box>
  );
};

export default Footer;
