import React, { useCallback } from "react";
import CommonButton from "./CommonButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import style from "./ErrorFallbackComponent.module.css";
import { imageBaseURL } from "../constants/utils";

const ErrorFallbackComponent = ({ error, resetErrorBoundary = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const backButtonClickHandler = useCallback((e) => {
    e.preventDefault();
    let endpoint = window?.location?.pathname;
    if (endpoint === "/home") dispatch({ type: "USER_LOGOUT" });
    navigate(-1);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }, []);
  return (
    <div className={style["fallback-main-component"]}>
      <img
        className={style["error-fallabck-exclamation-image"]}
        src={`${imageBaseURL}/ErrorExclamation.svg`}
        alt="Error Exclamation"
        loading="lazy"
      />
      <h1>Something Went Wrong</h1>
      <CommonButton
        sx={{ width: "20%", mt: "1rem" }}
        label="Go back"
        onClick={backButtonClickHandler}
      />
    </div>
  );
};

export default ErrorFallbackComponent;
