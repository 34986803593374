import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "All",
};

export const AgeingTabSlice = createSlice({
  name: "ActiveTab",
  initialState,
  reducers: {
    activeTabReducer: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});
export const { activeTabReducer } = AgeingTabSlice.actions;
export default AgeingTabSlice.reducer;
