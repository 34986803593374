import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const gradeCaseRd = createSlice({
  initialState: {
    isGradeOneSubmitted: false,
    checkDeskTopVerification: true,
  },
  reducers: {
    setGradeOneStatus: (state, action) => {
      state.isGradeOneSubmitted = action.payload;
    },
    isNotDeskTopVerification: (state, action) => {
      state.checkDeskTopVerification = action.payload;
    },
  },
  name: "grade",
});

export const { setGradeOneStatus, isNotDeskTopVerification } =
  gradeCaseRd.actions;
export const selectGradeOneStatus = (state) =>
  state.gradeCaseRd.isGradeOneSubmitted;
export default gradeCaseRd.reducer;
