import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const sliderBarElementsStyle = {
  display: "flex",
  flexwrap: "wrap",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.8rem",
  cursor: "pointer",
};

const sliderBarElementsStyle_2 = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const SideBarElements = ({
  sideBarIcon,
  sideBarlabel,
  imageAltLabel,
  onClick,
  labelColor,
  fontWeight,
  id,
}) => {
  return (
    <>
      <Box onClick={onClick} sx={sliderBarElementsStyle} id={id}>
        <Box sx={sliderBarElementsStyle_2}>
          <img src={sideBarIcon} alt={imageAltLabel} loading="lazy" />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "1.18rem",
            lineHeight: "1.3rem",
            fontWeight: fontWeight,
            color: labelColor,
          }}
        >
          {sideBarlabel}
        </Typography>
      </Box>
    </>
  );
};
SideBarElements.propTypes = {
  sideBarlabel: PropTypes.string,
  sideBarIcon: PropTypes.string,
  imageAltLabel: PropTypes.string,
  onClick: PropTypes.func,
  labelColor: PropTypes.string,
};
export default SideBarElements;
