import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchDetails: [],
  searchValue: "",
  searchSource: "",
  searchType: "",
  page: 1,
};

export const SearchSlice = createSlice({
  name: "Search",
  initialState,
  reducers: {
    searchReducer: (state, action) => {
      state.searchDetails = action.payload;
    },
    searchValueReducer: (state, action) => {
      state.searchValue = action.payload;
    },
    searchSourceReducer: (state, action) => {
      state.searchSource = action.payload;
    },
    searchTypeReducer: (state, action) => {
      state.searchType = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});
export const {
  searchReducer,
  searchValueReducer,
  searchSourceReducer,
  searchTypeReducer,
  setPage,
} = SearchSlice.actions;
export default SearchSlice.reducer;
