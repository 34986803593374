import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: "",
  token: "",
  isLoadingState: false,
};

export const loginSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    userDataReducer: (state, action) => {
      state.userData = action.payload;
    },
    saveToken: (state, action) => {
      state.token = action.payload;
    },
    isLoadingStateReducer: (state, action) => {
      state.isLoadingState = action.payload;
    },
  },
});
export const { userDataReducer, saveToken, isLoadingStateReducer } =
  loginSlice.actions;
export default loginSlice.reducer;
