import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import BottomNavigationElement from "./BottomNavigationElement";
import { imageBaseURL } from "../../constants/utils";

export default function BottomNavigation() {
  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: "auto",
          bottom: 10,
          left: "9.48%",
          right: "9.74%",
          justifyContent: "center",
          display: { xs: "flex", sm: "none", md: "none" },
          backgroundColor: "#E94D51",
          width: "81%",
          height: "6.6rem",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5rem",
          "&.MuiButtonBase-root": {
            "&:hover": {
              backgroundColor: "none",
            },
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <BottomNavigationElement
            BottomNavigationIcon={`${imageBaseURL}/Svg/Home - Copy.svg`}
            BottomNavigationIconAlt="HomeButoon"
            BottomNavigationIconLabel="Home"
          />
          <BottomNavigationElement
            BottomNavigationIcon={`${imageBaseURL}/Svg/icon-park-outline_more-app.svg`}
            BottomNavigationIconAlt="ReportsIcon"
            BottomNavigationIconLabel="Reports"
          />
          <BottomNavigationElement
            BottomNavigationIcon={`${imageBaseURL}/Svg/majesticons_search-line.svg`}
            BottomNavigationIconAlt="SearchIcon"
            BottomNavigationIconLabel="Search"
          />
          <BottomNavigationElement
            BottomNavigationIcon={`${imageBaseURL}/Svg/charm_menu-hamburger.svg`}
            BottomNavigationIconAlt="HamburgerIcon"
            BottomNavigationIconLabel="More"
          />
        </Box>
      </AppBar>
    </>
  );
}
